const routes = {
  org: {
    detail: '/orgs/:id',
    programming: '/orgs/:id/programming',
    program: '/orgs/:orgId/programming/:id',
    users: '/orgs/:id/users',
    sites: '/orgs/:id/sites',
    site: '/orgs/:orgId/sites/:id',
    siteDevice: '/orgs/:orgId/sites/:siteId/devices/:id',
  },
};

export default routes;
