import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Container from './Container';
import routes from './routes';
import CreateOrgInvitation from './CreateOrgInvitation';

const GET_SITE = gql`
  query getSite($siteId: ID!) {
    site(id: $siteId) {
      id
      name
      devices {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

const OrgDetail = () => {
  const history = useHistory();
  const { orgId, id } = useParams();
  const { loading, data = {} } = useQuery(GET_SITE, {
    variables: {
      siteId : id,
    },
  });
  const { site = {} } = data;
  const { devices = {} } = site;
  const { edges = [] } = devices;
  const deviceNodes = edges.map(({ node }) => node);

  return (
    <Container>
      <div style={{ height: '100%', width: '100%' }}>
        <DataGrid
          autoHeight={true}
          hideFooter={true}
          disableSelectionOnClick={true}
          onRowClick={(o) => {
            history.push(generatePath(routes.org.siteDevice, {
              id: o.id,
              siteId:id,
              orgId,
            }));
          }}
          columns={[
            { field: 'id', headerName: 'ID', width: 'auto' },
          ]}
          rows={deviceNodes || []}
        />
      </div>
    </Container>
  );
};

export default OrgDetail;
