import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Modal,
} from '@mui/material';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

const CREATE_ASSET = gql`
  mutation createAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      asset {
        id
        url
        fileName
      }
    }
  }
`;

const CREATE_SIGNED_UPLOAD = gql`
  mutation create($input:CreateSignedUploadInput!) {
    createSignedUpload(input:$input) {
      signedUpload {
        url
        fields
      }
    }
  }
`;

const GET_ASSETS = gql`
  query getAssets($orgId: ID!) {
    assets(orgId: $orgId) {
      id
      fileName
      url
      createdAt,
    }
  }
`;

const ModalContent = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  boxShadow: '0 2px 25px rgba(0,0,0,0.3)',
  ':focus-visible': {
    outline: 'none',
  }
});

const AssetListItem = ({ onSelect, id, fileName, url, createdAt }) => {
  return (
    <li key={id} onClick={() => onSelect({
      id,
      fileName,
      url,
      createdAt,
    })} style={{
      borderTop: '1px solid #EEE',
      padding: '8px 20px',
    }}>
      <Grid container>
        <Grid item>
          <div style={{
            marginRight: '10px',
            width: '50px',
            height: '50px',
            backgroundSize: 'contain',
            backgroundImage: `url(${url})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
          }} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">{fileName}</Typography>
          <Typography variant="body2">{new Date(createdAt).toLocaleString()}</Typography>
        </Grid>
      </Grid>
    </li>
  );
}

const AssetModal = ({ onSelect = () => {}, orgId }) => {
  const [open, setOpen] = useState(true);
  const { loading, data = {} } = useQuery(GET_ASSETS, {
    variables: {
      orgId,
    },
  });
  const [createSignedUpload, { data: createSignedUploadData }] = useMutation(CREATE_SIGNED_UPLOAD);
  const [createAsset, { data: createAssetData }] = useMutation(CREATE_ASSET);
  const { assets } = data;

  const handleFileInputChange = async (e) => {
    e.preventDefault();
    const { target } = e;
    const { files } = target;
    const [file] = files;
    const { name, type, size } = file
    const result = await createSignedUpload({
      variables: {
        input: {
          orgId,
        },
      }
    });
    const { data } = result;
    const { createSignedUpload: csu } = data;
    const { signedUpload } = csu;
    const { url, fields } = signedUpload;
    const parsedFields = JSON.parse(fields);
    const formData = new FormData();

    formData.append("Content-Type", file.type);
    Object.keys(parsedFields).forEach(key => formData.append(key, parsedFields[key]));
    formData.append('file', file);

    const uploadResult = await fetch(url, {
      method: 'POST',
      body: formData,
    });

    const createAssetResult = await createAsset({
      variables: {
        input: {
          orgId,
          fileName: name,
          fileRepository: 'ASSETS',
          path: parsedFields.Key,
        },
      }
    });
    const { data: createAssetData = {}} = createAssetResult;
    const { asset } = createAssetData.createAsset;
    onSelect(asset);
    return;
  };

  return (
    <Modal
      open={open}
    >
      <ModalContent>
        <Grid container>
          <Grid xs={7} style={{ backgroundColor: '#FFF', borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px' }}>
            <div style={{ padding: '20px 20px 0 20px' }}>
              <Typography variant="overline">
                Recent
              </Typography>
            </div>
            <ol style={{ height: '300px', overflowY: 'scroll', listStyle: 'none', padding: 0, margin: 0 }}>
              {assets && assets.map(asset => (
                <AssetListItem {...asset} onSelect={onSelect} />
              ))}
            </ol>
          </Grid>
          <Grid xs={5} style={{ padding: '20px', borderLeft: '1px solid #DDD', backgroundColor: '#EEE', borderTopRightRadius: '6px', borderBottomRightRadius: '6px' }}>
            <Typography variant="overline">
              Upload
            </Typography>
            <div onDragOver={(e) => e.preventDefault()} onDrop={(e) => e.preventDefault()} style={{ height: '200px', borderRadius: '14px', border: '3px dashed #AAA', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="body">Drag & Drop Here</Typography>
            </div>
            <div style={{ marginTop: '20px' }}>
              <input onChange={handleFileInputChange} style={{ display: 'none' }} id="input-asset-upload" type="file" accept=".jpg,.jpeg" />
              <Button component="label" htmlFor="input-asset-upload" style={{ width: '100%' }} variant="contained">Select File</Button>
            </div>
          </Grid>
        </Grid>
      </ModalContent>
    </Modal>
  );
};

export default AssetModal;
