import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

const SUBMIT_CREATE_ORG = gql`
  mutation createOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      org {
        id
      }
    }
  }
`;

const CreateOrg = () => {
  const [submitCreateOrg, { data, loading, error }] = useMutation(SUBMIT_CREATE_ORG);
  const [formState, setFormState] = useState({
    name: null,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = {
      org: formState,
    };

    try {
      await submitCreateOrg({ variables: { input }});
    } catch (e) {
      throw e;
    }
  };

  if (!loading && !error && data) {
    return <Redirect to={`/orgs/${data.createOrg.org.id}`} />
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} style={{ margin: '5px 0' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('name')}
                id="name"
                label="Name"
                placeholder="Name" />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" type="submit">Continue</Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateOrg;


