import React, { useState } from 'react';
import { format } from 'date-fns';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useUser } from './user';
import AssetModal from './AssetModal';
import routes from './routes';
import CreateProgramItem from './CreateProgramItem';

const GET_PROGRAM = gql`
  query getProgram($id: ID!, $date: Date!) {
    program(id: $id) {
      id
      name
      schedule(date: $date) {
        items {
          asset {
            fileName
          }
          exclusive
        }
      }
      items {
        edges {
          node {
            id
            asset {
              fileName
              url
            }
            startDate
            endDate
            exclusive
          }
        }
      }
    }
  }
`;

const DELETE_PROGRAM_ITEM = gql`
  mutation deleteProgramItem($where: ProgramItemSearch!) {
    deleteProgramItem(where: $where) {
      success
    }
  }
`;

const ProgramCalendarRow = ({ hours, asset, exclusive, }) => {
  const {
    fileName,
  } = asset;
  const getBorderRadius = i => {
    if (i === 0) return '16px  0 0 16px';
    if (i === 23) return '0 16px 16px 0';
    return '0';
  };

  return (
    <tr>
      {hours.map((h, i) => (
        <td style={{
          width: `${1/24}%`,
          padding: 0,
            overflow: 'visible',
            whiteSpace: 'nowrap'
        }}>
          <div style={{
            width: '100%',
            height: '28px',
            padding: '0 10px',
            borderRadius: getBorderRadius(i),
            backgroundColor: exclusive
              ? '#b71c1c'
              : '#03a9f4',

          borderLeft: i === 0
            ? 'none'
            : '1px solid rgba(255,255,255,0.2)',
            color: '#FFF',
            fontSize: '12px',
            lineHeight: '28px',
            marginBottom: '1px',
          }}>
            {i === 0 ? fileName : ''}
          </div>
        </td>
      ))}
    </tr>
  );
};

const ProgramCalendar = ({ schedule = {} }) => {
  const { items = [] } = schedule;
  const hours = [
    '0:00',
    '1:00',
    '2:00',
    '3:00',
    '4:00',
    '5:00',
    '6:00',
    '7:00',
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
  ];
  return (
    <div style={{
      padding: '25px 0',
    }}>
      <table cellspacing="0" style={{
        tableLayout: "fixed",
        borderSpacing: 0,
        borderCollapse: 'collapse',
        width: '100%',
      }}>
        <tbody style={{ width: '100%' }}>
          {items.map(f => (<ProgramCalendarRow hours={hours} {...f} />))}
          <tr>
          {hours.map((h, i) => (
            <td style={{ fontSize: '12px', width: `${1/24}%` }}>{i === 0 ? '' : h}</td>
          ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const Program = () => {
  const today = format(new Date(), 'yyyy-MM-dd');
  const history = useHistory();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const { orgId, id } = useParams();

  const [submitDeleteProgramItem, { loading: delteProgramItemLoading, error: deleteProgramItemError }] = useMutation(DELETE_PROGRAM_ITEM);
  const { loading, data = {}, refetch } = useQuery(GET_PROGRAM, {
    variables: {
      id,
      date: today,
    },
  });
  const { program = {} } = data;
  const { schedule, items = {} } = program;
  const { edges = [] } = items;
  const programItems = edges.map(({ node }) => node);

  const onCreateProgramComplete = () => {
    // refactor dialog component into create component and execute close()
    setCreateDialogOpen(false);
    refetch();
  };

  const handleDeleteProgramItem = async (id) => {
    const where = { id };
    await submitDeleteProgramItem({ variables: { where }});
  };

  return (
    <div style={{ height: '100%', width: '100%', padding: '15px 30px' }}>
      <Grid container>
        <Grid xs item>
          <Typography variant="h6">{program.name}</Typography>
        </Grid>
        <Grid xs={3} container item justifyContent="right">
          <Grid item>
            <Button variant="outlined" onClick={() => setCreateDialogOpen(true)} >Create Program Item</Button>
          </Grid>
        </Grid>
      </Grid>
      <ProgramCalendar schedule={schedule} />
      <DataGrid
        autoHeight={true}
        hideFooter={true}
        disableSelectionOnClick={true}
        columns={[
          { field: 'preview', headerName: '', width: 60, valueGetter: ({ row }) => {
              const { asset } = row;
              const { url } = asset;
              return url;
            }, renderCell: ({ value }) => (
              <div style={{
                width: '60px',
                height: '60px',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundImage: `url(${value})`,
                backgroundRepeat: 'no-repeat',
              }} />
            )
          },
          { field: 'fileName', headerName: 'File Name', width: 200, valueGetter: ({ row }) => {
              const { asset } = row;
              const { fileName } = asset;
              return fileName;
            }
          },
          { field: 'startDate', headerName: 'Start Date', width: 100 },
          { field: 'endDate', headerName: 'End Date', width: 100 },
          { field: 'exclusive', headerName: 'Exclusive', width: 100 },
          {
            field: 'actions',
            type: 'actions',
            getActions: ({ id }) => [
              <GridActionsCellItem icon={<DeleteIcon />} onClick={() => handleDeleteProgramItem(id)} label="Delete" />
            ]
          },
        ]}
        rows={programItems}
      />
      <Dialog maxWidth='md' onClose={() => setCreateDialogOpen(false)} open={createDialogOpen}>
        <DialogTitle>Create Program Item</DialogTitle>
        <DialogContent>
          <CreateProgramItem programId={id} orgId={orgId} onComplete={onCreateProgramComplete} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Program;
