import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import {
  generatePath,
} from 'react-router';
import CssBaseline from '@mui/material/CssBaseline';

import routes from './routes';
import LoggedInLayout from './LoggedInLayout';
import UserHome from './UserHome';
import Signup from './Signup';
import SignIn from './Signin';
import OrgsAdmin from './OrgsAdmin';
import OrgProgramming from './OrgProgramming';
import Program from './Program';
import OrgUsers from './OrgUsers';
import OrgSites from './OrgSites';
import OrgSite from './OrgSite';
import Device from './Device';
import OrgHeader from './OrgHeader';
import { useUser } from './user';

const AdminRoute = ({ children, ...rest }) => {
  const { user } = useUser();
  const isAdmin = user && user.groups.includes('SITE_ADMIN');

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAdmin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const LoggedInRoute = ({ children, ...rest }) => {
  const { user } = useUser();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/sign-in",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const AdminRouter = () => {
  const { path } = useRouteMatch();

  return (
    <AdminRoute>
      <Switch>
        <Route exact path={path}>
          <>Hello, Admin.</>
        </Route>
        <Route exact path={`${path}/orgs`}>
          <OrgsAdmin />
        </Route>
      </Switch>
    </AdminRoute>
  );
};

const OrgRouter = () => (
  <LoggedInRoute>
    <OrgHeader />
    <Switch>
      <Route exact path={routes.org.detail}>
        {({ match }) => <Redirect to={generatePath(routes.org.programming, { id: match.params.id })} />}
      </Route>
      <Route exact path={routes.org.programming}>
        <OrgProgramming />
      </Route>
      <Route exact path={routes.org.program}>
        <Program />
      </Route>
      <Route exact path={routes.org.users}>
        <OrgUsers />
      </Route>
      <Route exact path={routes.org.sites}>
        <OrgSites />
      </Route>
      <Route exact path={routes.org.site}>
        <OrgSite />
      </Route>
      <Route exact path={routes.org.siteDevice}>
        <Device  />
      </Route>
    </Switch>
  </LoggedInRoute>
);

const App = () => (
  <Router>
    <CssBaseline />
    <Switch>
      <Route exact path="/">
        <SignIn />
      </Route>
      <Route path="/sign-in">
        <SignIn />
      </Route>
      <Route path="/sign-up">
        <Signup />
      </Route>
      <LoggedInRoute>
        <LoggedInLayout>
          <Switch>
            <Route exact path="/home">
              <UserHome />
            </Route>
            <Route path="/admin">
              <AdminRouter />
            </Route>
            <Route path={routes.org.detail}>
              <OrgRouter />
            </Route>
          </Switch>
        </LoggedInLayout>
      </LoggedInRoute>
    </Switch>
  </Router>
);

export default App;
