import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Grid,
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import Container from './Container';
import routes from './routes';
import CreateOrgInvitation from './CreateOrgInvitation';

const GET_DEVICE = gql`
  query getDeviec($where: DeviceSearch!) {
    device(where: $where) {
      id
      modules {
        cdpFeedback {
          enabled
        }
        cdpLotto {
          enabled
        }
        cdpMultimedia {
          enabled
          program {
            id
            name
          }
        }
      }
    }
  }
`;

const UPDATE_DEVICE_MODULES = gql`
  mutation updateDeviceModules($input: UpdateDeviceModulesInput!) {
    updateDeviceModules(input: $input) {
      success
    }
  }
`;

const DeviceDetail = () => {
  const history = useHistory();
  const { id } = useParams();

  const [submitUpdateDeviceModules, {
    data: updateDeviceModulesResult,
    loading: updateDeviceModulesLoading,
    error,
  }] = useMutation(UPDATE_DEVICE_MODULES);
  const { loading, data = {} } = useQuery(GET_DEVICE, {
    variables: {
      where: {
        deviceId: id,
      }
    },
  });
  const { device = {} } = data;
  const [formState, setFormState] = useState({
    cdpLotto: {
      enabled: false,
    },
    cdpFeedback: {
      enabled: false,
    },
    cdpMultimedia: {
      enabled: false,
      programId: null,
    },
  });

  useEffect(() => {
    if (loading) return;

    const { modules = {} } = device;
    const {
      cdpLotto = {},
      cdpFeedback = {},
      cdpMultimedia = {},
    } = modules;

    setFormState({
      cdpLotto: {
        enabled: cdpLotto.enabled,
      },
      cdpFeedback: {
        enabled: cdpFeedback.enabled,
      },
      cdpMultimedia: {
        enabled: cdpMultimedia.enabled,
      },
    });
  }, [loading, device]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const input = {
      deviceId: id,
      ...formState
    };
    await submitUpdateDeviceModules({ variables: { input }});
  };


  if (loading) return;

  return (
    <Container>
      <div style={{ height: '100%', width: '100%', marginTop: '25px' }}>
        <Typography variant="h6">Module Configuration</Typography>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Typography variant="subtitle">Lotto Module</Typography>
            <FormControlLabel checked={formState.cdpLotto.enabled} onChange={e => setFormState({...formState, cdpLotto: { enabled: e.target.checked }})} control={<Checkbox />} label="enabled" />
          </FormGroup>
          <FormGroup>
            <Typography variant="subtitle">Feedback Module</Typography>
            <FormControlLabel checked={formState.cdpFeedback.enabled} onChange={e => setFormState({...formState, cdpFeedback: { enabled: e.target.checked }})} control={<Checkbox />} label="enabled" />
          </FormGroup>
          <FormGroup>
            <Typography variant="subtitle">Multimedia Module</Typography>
            <FormControlLabel checked={formState.cdpMultimedia.enabled} onChange={e => setFormState({...formState, cdpMultimedia: { enabled: e.target.checked }})} control={<Checkbox />} label="enabled" />
          </FormGroup>
          <Button disabled={updateDeviceModulesLoading} type="submit">{updateDeviceModulesLoading ? <CircularProgress /> : 'Submit'}</Button>
        </form>
      </div>
    </Container>
  );
};

export default DeviceDetail;
