import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth } from 'aws-amplify';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from 'apollo-upload-client';
import { ApolloProvider } from '@apollo/react-hooks';
import './index.css';
import App from './App';
import { UserProvider } from './user';

const REACT_APP_GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID;
const REACT_APP_COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;
const REACT_APP_COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION;
const REACT_APP_COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN;
const REACT_APP_DOMAIN = process.env.REACT_APP_DOMAIN;

const oauth = {
  domain: REACT_APP_COGNITO_DOMAIN,
  scope: ['profile', 'openid','aws.cognito.signin.user.admin'],
  redirectSignIn : `https://${REACT_APP_DOMAIN}/home`,
  redirectSignOut: `https://${REACT_APP_DOMAIN}/sign-in`,
  responseType: 'code',
};

Auth.configure({
  region: REACT_APP_COGNITO_REGION,
  userPoolId: REACT_APP_USER_POOL_ID,
  userPoolWebClientId: REACT_APP_COGNITO_CLIENT_ID,
  authenticationFlowType: 'USER_PASSWORD_AUTH',
  oauth,
});

const authLink = setContext(async (_, { headers }, ...x) => {
  const session = await Auth.currentSession();

  //Auth.currentAuthenticatedUser({
  //  bypassCache: false,
  //}).then(user => console.log(user))
  //  .catch(err => console.log(err));

  return {
    headers: {
      ...headers,
      authorization: session && `Bearer ${session.idToken.jwtToken}`,
      'x-app-platform': 'web',
      'x-app-version': process.env.REACT_APP_DEPLOY_VERSION,
    },
  };
});

const httpLink = createUploadLink({ uri: REACT_APP_GRAPHQL_ENDPOINT });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      errorPolicy: 'all',
    },
  },
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <React.StrictMode>
     <ApolloProvider client={client}>
      <UserProvider>
        <App />
      </UserProvider>
    </ApolloProvider>
  </React.StrictMode>,
);
