import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useUser } from './user';
import Container from './Container';
import routes from './routes';
import CreateOrgInvitation from './CreateOrgInvitation';

const GET_ORG = gql`
  query getOrg($orgId: ID!) {
    org(id: $orgId) {
      users {
        edges {
          node {
            id
            givenName
            familyName
          }
          createdAt
        }
      }
      invitations {
        edges {
          node {
            id
            email
            createdAt
          }
        }
      }
    }
  }
`;

const OrgDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  const { user } = useUser();
  const [createInvitationDialogOpen, setCreateInvitationDialogOpen] = useState(false);
  const { loading, data = {} } = useQuery(GET_ORG, {
    variables: {
      orgId: id,
    },
  });
  const { org = {} } = data;
  const { users = {} } = org;
  const { edges = [] } = users;
  const orgUsers = edges.map(({ node }) => node);

  const onCreateInvitationButtonClick = () => {
    setCreateInvitationDialogOpen(true);
  };

  return (
    <>
      <Dialog maxWidth='md' onClose={() => setCreateInvitationDialogOpen(false)} open={createInvitationDialogOpen}>
        <DialogTitle>Create Invitation</DialogTitle>
        <DialogContent>
          <CreateOrgInvitation orgId={id} onDone={() => setCreateInvitationDialogOpen(false)} />
        </DialogContent>
      </Dialog>
      <Container>
        <Grid container justifyContent="flex-end" style={{ margin: '15px 0' }}>
          <Grid item>
            <Button variant="outlined" onClick={onCreateInvitationButtonClick}>Invite user</Button>
          </Grid>
        </Grid>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid
            autoHeight={true}
            hideFooter={true}
            disableSelectionOnClick={true}
            getRowId={({ id }) => id}
            columns={[
              { flex: 1, field: 'givenName', valueGetter: ({ row }) => `${row.familyName}, ${row.givenName}`, headerName: 'Name', width: 100 },
            ]}
            rows={orgUsers || []}
          />
        </div>
      </Container>
    </>
  );
};

export default OrgDetail;
