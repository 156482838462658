import React  from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import {
  generatePath,
} from 'react-router';
import {
  Link,
  useParams,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Button,
  Grid,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import Container from './Container';
import routes from './routes';

const OrgHeaderContainer = styled(Container)`
  background-color: #FFF;
  border-bottom: 1px solid #E6E6E6;
`;

const GET_ORG = gql`
  query getOrg($orgId: ID!) {
    org(id: $orgId) {
      id
      name
    }
  }
`;

const isProgramming = s => /\/programming$/.test(s);
const isUsers = s => /\/users$/.test(s);
const isSites = s => /\/sites$/.test(s);

const tabCheck = [
  isProgramming,
  isUsers,
  isSites,
];

const pageTitle = {
  0: 'Programming',
  1: 'Settings',
  2: 'Sites',
};

const OrgHeader = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state = {} } = location;
  const tabValue = tabCheck.findIndex(fn => fn(location.pathname));
  const { loading: loadingGetOrg, data: getOrgResult = {} } = useQuery(GET_ORG, {
    skip: state.org,
    variables: {
      orgId: id,
    },
  });

  const org = loadingGetOrg ? {} : state.org || getOrgResult.org || {};

  return (
    <>
      <Helmet>
        <title>{org && org.name ? org.name : 'Org'} - {pageTitle[tabValue] || ''}</title>
      </Helmet>
      <OrgHeaderContainer>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6' style={{ lineHeight: '48px' }}>{org.name}</Typography>
          </Grid>
          <Grid item container xs={12} sm={6} justifyContent="flex-end" alignItems="flex-end">
            <Grid item>
              <Tabs value={tabValue} aria-label="nav tabs">
                <Tab label="Programming" to={generatePath(routes.org.detail, { id })} component={Link} />
                <Tab label="Users" to={generatePath(routes.org.users, { id })} component={Link} />
                <Tab label="Sites" to={generatePath(routes.org.sites, { id })} component={Link} />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>
      </OrgHeaderContainer>
    </>
  );
};

export default OrgHeader;
