import React from 'react';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {
  Link,
  useLocation,
  useParams,
} from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useUser } from './user';
import AssetModal from './AssetModal';
import routes from './routes';

const GET_ORG = gql`
  query getOrg($orgId: ID!) {
    org(id: $orgId) {
      id
      name
      programs(type: IDLE_SCREEN) {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

const OrgProgramming = () => {
  const { id } = useParams();
  const history = useHistory();
  const { loading, data = {} } = useQuery(GET_ORG, {
    variables: {
      orgId: id,
    },
  });
  const  { org = {} } = data;
  const { id: orgId } = org;
  const { programs = {} } = org;
  const { edges: programEdges = [] } = programs;
  const programNodes = programEdges.map(({ node }) => node);

  return (
    <div style={{ height: '100%', width: '100%', padding: '15px 30px' }}>
      <DataGrid
        autoHeight={true}
        hideFooter={true}
        disableSelectionOnClick={true}
        onRowClick={(o) => {
          history.push(generatePath(routes.org.program, {
            orgId,
            id: o.id,
          }));
        }}
        columns={[
          { field: 'name', headerName: 'Name', width: 300 },
        ]}
        rows={programNodes}
      />
    </div>
  );
};

export default OrgProgramming;
