import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
  Grid,
  Typography,
  Button,
  TextField,
} from '@mui/material';
import { useUser } from './user';

const Signup = () => {
  const { signIn, signUp } = useUser();
  const [formState, setFormState] = useState({
    email: null,
    password: null,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signUp(formState);
      await signIn(formState.email, formState.password);
    } catch (e) {
      throw e;
    }
  };

  return (
    <Grid>
      <Grid item>
        <form onSubmit={handleSubmit}>
          <TextField outlined required label="Email" type="email" onChange={setStateProp('email')} />
          <TextField outlined required label="Password" type="password" minlength="8" autocomplete="new-password" onChange={setStateProp('password')} />
          <div>
            <Button label="Sign up" />
          </div>
        </form>
      </Grid>
    </Grid>
  );
}

export default Signup;
