import React, { useState, useRef } from 'react';
import { ReactComponent as LogoFull } from './assets/logo.svg';
import {
  Avatar,
  Grid,
  Menu,
  MenuItem,
  ListItemIcon,
} from '@mui/material';
import Logout from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import Container from './Container';
import { useUser} from './user';

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const stringAvatar = (first = '', last = '', { sx = {} } = {}) => ({
  sx: {
    ...sx,
    bgcolor: stringToColor(`${first} ${last}`),
    fontSize: '16px',
  },
  children: `${first.charAt(0)}${last.charAt(0)}`,
});

const GlobalHeader = () => {
  const [showMenu, setShowMenu] = useState(false);
  const avatarRef = useRef(null);
  const { user, signOut } = useUser();

  return (
    <div style={{ backgroundColor: '#FFF', borderBottom: '1px solid #E6E6E6' }}>
      <Container>
        <Grid container style={{
          padding: '15px 0',
        }}>
          <Grid item xs={6}>
            <Link to='/home'>
              <LogoFull width={100} style={{ display: 'block' }}/>
            </Link>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <Grid item>
              {user && (
              <Avatar ref={avatarRef} {...stringAvatar(user.givenName, user.familyName, { sx: { width: 30, height: 30 }})} onClick={() => setShowMenu(true)}/>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Container>

     <Menu
        anchorEl={avatarRef && avatarRef.current}
        id="account-menu"
        open={showMenu}
        onClose={() => setShowMenu(false)}
        onClick={(e) => {
          setShowMenu(false)
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => {
          signOut();
        }}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default GlobalHeader;
