import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';

const SUBMIT_CREATE_ORG_INVITATION = gql`
  mutation createOrg($input: CreateOrgInvitationInput!) {
    createOrgInvitation(input: $input) {
      invitation {
        id
      }
    }
  }
`;

const CreateOrgInvitation = ({ orgId, onDone }) => {
  const [submitCreateOrgInvitation, { data, loading, error }] = useMutation(SUBMIT_CREATE_ORG_INVITATION);
  const [formState, setFormState] = useState({
    email: null,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = {
      invitation: {
        orgId,
        ...formState,
      }
    };

    try {
      await submitCreateOrgInvitation({ variables: { input }});
    } catch (e) {
      throw e;
    }
  };

  if (!loading && !error && data) {
    onDone(data);
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} style={{ margin: '5px 0' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField type="text"
                fullWidth
                required
                onChange={setStateProp('email')}
                id="email"
                label="Email"
                placeholder="john.doe@gmail.com" />
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" type="submit">Continue</Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default CreateOrgInvitation;
