import React, { useState } from 'react';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  Button,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Container from './Container';
import CreateOrg from './CreateOrg';
import { useUser } from './user';
import routes from './routes';

const GET_ORGS = gql`
  query getOrgs {
    orgs {
      nodes {
        id
        name
        createdAt
      }
    }
  }
`;

const formatOrgDate = ({ value }) =>
  new Date(value).toString();

const NavBar = ({ onCreateOrgClick }) => (
  <div style={{ padding: '25px 0' }}>
    <Container>
      <Grid item container spacing={2} style={{ marginBottom: '25px' }}>
        <Grid item xs={8}>
          <Typography variant="h5">Orgs</Typography>
        </Grid>
        <Grid item container xs={4} justifyContent="flex-end">
          <Grid item>
            <Button variant="outlined" onClick={onCreateOrgClick}>Create Org</Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  </div>
  );

export default () => {
  const history = useHistory();
  const { user } = useUser();
  const { loading, data = {}, error, refetch: getOrgs } = useQuery(GET_ORGS, {
    skip: !user,
  });
  const [createOrgDialogOpen, setCreateOrgDialogOpen] = useState(false);
  const  { orgs = {} } = data;
  return (
    <>
      <Dialog maxWidth='md' onClose={() => setCreateOrgDialogOpen(false)} open={createOrgDialogOpen}>
        <DialogTitle>Create Org</DialogTitle>
        <DialogContent>
          <CreateOrg />
        </DialogContent>
      </Dialog>
      <NavBar onCreateOrgClick={() => setCreateOrgDialogOpen(true)}/>
      <div style={{ height: '100%', width: '100%', padding: '0 30px' }}>
        <DataGrid
          autoHeight={true}
          hideFooter={true}
          disableSelectionOnClick={true}
          onRowClick={(o) => {
            history.push(generatePath(routes.org.detail, { ...o }));
          }}
          columns={[
            { field: 'name', headerName: 'Name', width: 100 },
            { field: 'createdAt', headerName: 'Created', width: 200, valueFormatter: formatOrgDate },
          ]}
          rows={orgs.nodes || []}
        />
      </div>
    </>
  );
};
