import React, { useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { Auth } from 'aws-amplify';
import {
  Grid,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import { useUser } from './user';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import GoogleMark from './icons/google-mark.svg';

const GoogleSignInButton = styled(Button)`
  text-transform: none;
  width: 100%;
  margin: 20px 0;
  background-color: #FFF;
  color: #999;
  :hover {
    background-color: #FFF;
  }
`;

const Container = styled.div`
  max-width: 420px;
  margin: 100px auto;
  padding: 0 25px;
`;

const SignIn = () => {
  const location = useLocation();
  const history = useHistory();
  const { user, signIn } = useUser();
  const [formState, setFormState] = useState({
    email: null,
    password: null,
  });

  const { state = {} } = location;
  const { from = {} } = state;
  const { pathname } = from;
  const to = pathname && pathname === '/login'
    ? '/home'
    : pathname;

  if (user) return <Redirect to={to} />

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signIn(formState.email, formState.password);
      history.push(to);
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      <Helmet>
        <title>GAS360 - Login</title>
      </Helmet>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item xs={6}>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <GoogleSignInButton size="large" startIcon={<img src={GoogleMark} />} variant="contained" onClick={() => Auth.federatedSignIn({ provider: 'Google' })}>Sign in with Google</GoogleSignInButton>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <TextField required fullWidth label="Email" type="email" onChange={setStateProp('email')} />
                </Grid>
                <Grid item xs={12}>
                  <TextField required fullWidth label="Password" type="password" minLength="8" autoComplete="current-password" onChange={setStateProp('password')} />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <Grid item>
                    <Button type="submit" variant="contained" disableElevation>Sign in</Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SignIn;
