import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  FormHelperText,
  Input,
} from '@mui/material';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import styled from '@emotion/styled';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AssetModal from './AssetModal';
import { format } from 'date-fns';

const SUBMIT_CREATE_PROGRAM_ITEM = gql`
  mutation createProgramItem($input: CreateProgramItemInput!) {
    createProgramItem(input: $input) {
      programItem {
        id
      }
    }
  }
`;

const AssetInput = ({ asset, onButtonClick = () => {} }) => {
  return (
    <Grid container style={{
      padding: '0 12px',
      borderRadius: '4px',
      border: '1px solid rgba(0, 0, 0, 0.23)',
        lineHeight: '56px',
    }}>
      <Grid xs={8} item>
        <Typography variant="body" sx={{ color: '#555' }}>{asset ? asset.fileName : 'Choose Asset'}</Typography>
      </Grid>
      <Grid xs={4} item container justifyContent="flex-end">
        <Grid item>
          <Button onClick={onButtonClick} variant="outlined">Select/Upload</Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CreateProgramItem = ({ programId, orgId, onComplete }) => {
  const [assetModalVisible, setAssetModalVisible] = useState(false);
  const [submitCreateProgramItem, { data, loading, error }] = useMutation(SUBMIT_CREATE_PROGRAM_ITEM);
  const [formState, setFormState] = useState({
    startDate: null,
    endDate: null,
    asset: null,
    exclusive: false,
  });

  const setStateProp = (prop) => (e) => {
    setFormState({
      ...formState,
      [prop]: e.target.value,
    });
  };

  const handleAssetModalSelect = (asset) => {
    setAssetModalVisible(false);
    setFormState({
      ...formState,
      asset,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = {
      programId,
      assetId: formState.asset.id,
      startDate: format(formState.startDate, 'yyyy-MM-dd'),
      endDate: formState.endDate ? format(formState.endDate, 'yyyy-MM-dd') : null,
      exclusive: formState.exclusive,
    };

    try {
      await submitCreateProgramItem({ variables: { input }});
      onComplete();
    } catch (e) {
      throw e;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <form onSubmit={handleSubmit} style={{ margin: '5px 0' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <AssetInput asset={formState.asset} onButtonClick={() => setAssetModalVisible(true)} />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Start Date"
                  disablePast={true}
                  value={formState.startDate}
                  onChange={(value) => setFormState({
                    ...formState,
                    startDate: value,
                  })}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="End Date"
                  disablePast={true}
                  value={formState.endDate}
                  onChange={(value) => setFormState({
                    ...formState,
                    endDate: value,
                  })}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox />} value={formState.exclusive} onChange={(e) => {
                      const { target } = e;
                      const { checked } = target;
                      setFormState({
                        ...formState,
                        exclusive: checked,
                      });
                    }}label="Exclusive" />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Grid item>
                  <Button variant="contained" type="submit">Continue</Button>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </form>
      </Grid>
      {assetModalVisible && (
        <AssetModal onSelect={handleAssetModalSelect} orgId={orgId} />
      )}
    </Grid>
  );
};

export default CreateProgramItem;
