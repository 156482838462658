import React from 'react';
import {
  useHistory,
  generatePath,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import Container from './Container';
import routes from './routes';

const GET_VIEWER_ORGS = gql`
  query viewer {
     viewer {
      orgs {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
`;

export default () => {
  const history = useHistory();
  const { loading, data = {} } = useQuery(GET_VIEWER_ORGS);
  const { viewer = {} } = data;
  const { orgs: orgsConn = {} } = viewer;
  const { edges = [] } = orgsConn;
  const orgs = edges.map(({ node }) => node);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Container>
        <div style={{ height: '100%', width: '100%' }}>
          <DataGrid
            autoHeight={true}
            hideFooter={true}
            disableSelectionOnClick={true}
            onRowClick={(o) => {
              history.push(generatePath(routes.org.detail, {
                id: o.id,
              }));
            }}
            columns={[
              { field: 'id', headerName: 'ID', width: 'auto' },
            ]}
            rows={orgs || []}
          />
        </div>
      </Container>
    </>
  );

};
